<template>
  <v-combobox
    :items="countries"
    :placeholder="placeholder"
    item-text="name"
    item-value="code"
    :value="value"
    :return-object="true"
    color="rgba(0,0,0,.6)"
    class="
      input-style
      font-size-input
      border border-radius-md
      placeholder-lighter
      text-color-light
    "
    outlined
    single-line
    hide-details
    dense
    @input="updateValue"
  >
  </v-combobox>
</template>
<script>
let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  name: "countries-select",
  props: {
    placeholder: {
      type: String,
      default: "Countries",
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
  computed: {
    countries: function () {
      let list = countries.getNames("en", { select: "official" });

      return Object.entries(list).map(function (e) {
        return { name: e[1], code: e[0] };
      });
    },
  },
};
</script>
