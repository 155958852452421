<template>
  <v-main class="auth-pages">
    <div class="header-auth position-relative border-radius-xl min-vh-100">
      <v-container class="py-0">
        <v-row>
          <v-col lg="6" md="7" class="d-flex flex-column mx-lg-0 mx-auto">
            <v-card class="bg-transparent">
              <div class="card-padding pb-0">
                <h4 class="text-h4 font-weight-bolder text-typo mb-2">
                  {{ 'Submit request'|trans }}
                </h4>
                <p class="mb-0 text-body">
                  {{ 'Fill in this form to submit a help request'|trans }}
                </p>
              </div>
              <div class="card-padding pb-4">
                <v-row v-if="localAlert">
                  <v-col cols="12">
                    <v-alert :type="localAlert.type" :color="localAlert.color">
                      {{ localAlert.message }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row v-if="validationErrors">
                  <v-col cols="12" v-for="validationError in validationErrors">
                    <v-alert
                      :type="validationError.type"
                      :color="validationError.color"
                    >
                      {{ validationError.message }}
                    </v-alert>
                  </v-col>
                </v-row>

                <v-form v-model="valid" ref="form">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Full Name'|trans }}</label
                      >
                      <v-text-field
                        v-model="requestData.name"
                        hide-details
                        outlined
                        :rules="validation.required"
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        :placeholder="'Name'|trans"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Email'|trans }}</label
                      >
                      <v-text-field
                        v-model="requestData.email"
                        type="email"
                        hide-details
                        :rules="validation.required"
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        :placeholder="'Email'|trans"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Phone'|trans }}</label
                      >
                      <v-text-field
                        v-model="requestData.phone"
                        hide-details
                        outlined
                        :rules="validation.required"
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        :placeholder="'Phone'|trans"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Spoken languages'|trans }}</label
                      >
                      <v-combobox
                        :items="availableLanguages"
                        :placeholder="'Spoken languages'|trans"
                        :rules="validation.required"
                        item-text="name"
                        item-value="code"
                        multiple
                        clearable
                        :return-object="true"
                        v-model="requestData.spokenLanguages"
                        color="rgba(0,0,0,.6)"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                        "
                        outlined
                        hide-details
                        light
                        dense
                      >
                      </v-combobox>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Current location'|trans }}</label
                      >
                      <countries-select
                        v-model="requestData.currentCountry"
                        :placeholder="'Country'|trans"
                      ></countries-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="requestData.currentCity"
                        hide-details
                        outlined
                        :rules="validation.required"
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        dense
                        height="40"
                        :placeholder="'City'|trans"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mt-6
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Destination location'|trans }}</label
                      >

                      <countries-select
                        v-model="requestData.destinationCountry"
                        :placeholder="'Country'|trans"
                      ></countries-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="requestData.destinationCity"
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        dense
                        height="40"
                        :placeholder="'City'|trans"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mt-6
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Number of adults'|trans }}</label
                      >
                      <v-text-field
                        type="number"
                        min="0"
                        :rules="validation.required"
                        v-model="requestData.numberOfPeople"
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        dense
                        height="40"
                        :placeholder="'Number of people'|trans"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Number of children'|trans }}</label
                      >
                      <v-text-field
                        type="number"
                        min="0"
                        :rules="validation.required"
                        v-model="requestData.numberOfChildren"
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        dense
                        height="40"
                        :placeholder="'Children'|trans"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Required services'|trans }}</label
                      >
                      <AvailableServices
                        v-model="requestData.servicesList"
                        classList="
                       input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
"
                      ></AvailableServices>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >{{ 'Additional info'|trans }}</label>
                      <v-textarea
                        rows="2"
                        v-model="requestData.additionalInfo"
                        :rules="validation.required"
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        dense
                        :placeholder="'Write here any additional info that will help our volunteers'|trans"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                        "
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="tcCheckbox"
                        color="#141727"
                        :ripple="false"
                        class="ma-0 checkbox-custom checkbox-thinner"
                        hide-details
                      >
                        <template v-slot:label>
                          <span class="text-typo text-body-2 ls-0"
                            >{{ 'I agree with the'|trans }}
                            <a
                              @click="goToTermsAndConditions"
                              class="
                                text-dark
                                font-weight-bolder
                                text-decoration-none
                              "
                              >{{ 'Terms and Conditions'|trans }}</a
                            >
                            {{ "and I've read the"|trans }}
                            <a
                              @click="goToPrivacyPolicy"
                              class="
                                text-dark
                                font-weight-bolder
                                text-decoration-none
                              "
                              >{{ 'Privacy Policy'|trans }}</a
                            ></span
                          >
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <vue-recaptcha
                        :loadRecaptchaScript="recaptchaRequired"
                        ref="recaptcha"
                        v-if="recaptchaRequired"
                        :sitekey="recaptchaSiteKey"
                        @verify="captchaVerify"
                      ></vue-recaptcha>
                    </v-col>
                  </v-row>
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-uppercase
                      btn-primary
                      bg-gradient-default
                      py-2
                      px-6
                      me-2
                      mt-7
                      mb-2
                      w-100
                    "
                    color="#5e72e4"
                    small
                    @click="submitRequest"
                    :loading="isLoading"
                    >{{ 'Submit'|trans }}
                  </v-btn>
                  <div class="text-center">
                    <p class="text-sm text-body mt-3 mb-0">
                      {{ 'Already submitted a request ?'|trans }}
                      <router-link
                        :to="{ name: 'Login' }"
                        class="
                          text-default
                          text-gradient-default
                          text-decoration-none
                          font-weight-bold
                        "
                      >
                        {{ 'Sign in'|trans }}
                      </router-link>
                    </p>
                  </div>
                </v-form>
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            class="
              d-lg-flex d-none
              h-100
              my-auto
              pe-0
              pt-0
              position-absolute
              top-0
              end-0
              text-center
              justify-center
              flex-column
            "
          >
            <div
              class="
                position-relative
                bg-gradient-default
                h-100
                ma-4
                px-16
                border-radius-lg
                d-flex
                flex-column
                justify-center
              "
            >
              <v-img
                src="@/assets/img/shapes/pattern-lines.svg"
                alt="pattern-lines"
                class="position-absolute opacity-4 start-0 h-100 w-100"
              >
              </v-img>
              <h4 class="text-h4 mt-10 text-white font-weight-bolder mb-2">
                {{ 'Your journey starts here'|trans }}
              </h4>
              <v-list dark>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title> {{ 'How do I start ?'|trans }} </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{'Create an account by filling in the information requested on the left hand side fields.'|trans}}<br />{{"After filling in all the information, you will receive an email with username and password. Please check your Spam folder if you didn't receive it. Use the credentials received in the email to log in to the platform."|trans}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ 'How do I get in touch with the volunteers ?'|trans }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{'Based on the location and services you have selected when you created your account, a volunteer will contact you shortly via our chat. You will receive a notification when your case was assigned. Please use the "Messages" section on your request page to communicate with our volunteers.'|trans}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ 'Who are the volunteers ?'|trans }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{ 'All our volunteers are Yale students, employees or friends of Yalies.'|trans }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ 'In what language do I need to communicate ?'|trans }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{ 'English is preferred but the platform also automatically translates the chat from Ukrainian to English' | trans  }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ 'What information am I requested to share ?'|trans }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{
                        'It is highly recommended not to share any additional confidential information, other than the information requested when you are creating your account.'|trans
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-main>
</template>
<script>
import CountriesSelect from "@/components/CountriesSelect";
import AvailableServices from "@/components/AvailableServices";
import availableLanguages from "@/data/languages.json";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "submit-request",
  components: { CountriesSelect, AvailableServices, VueRecaptcha },
  data() {
    return {
      isLoading: false,
      tcCheckbox: false,
      localAlert: null,
      valid: false,
      validationErrors: [],
      validation: {
        required: [(v) => !!v || "Is required"],
      },
      requestData: {
        name: "",
        email: "",
        phone: "",
        currentCountry: null,
        currentCity: "",
        destinationCountry: null,
        destinationCity: "",
        additionalInfo: "",
        servicesList: [],
        numberOfPeople: "",
        numberOfChildren: "",
        spokenLanguages: [],
        captcha: null,
      },
      availableLanguages: availableLanguages,
      recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaRequired: process.env.NODE_ENV === "production",
    };
  },
  methods: {
    goToTermsAndConditions(e) {
      e.preventDefault();

      let route = this.$router.resolve({ name: "terms-and-conditions-page" });
      window.open(route.href);
    },
    goToPrivacyPolicy(e) {
      e.preventDefault();

      let route = this.$router.resolve({ name: "privacy-policy-page" });
      window.open(route.href);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      if (this.$refs.recaptcha) {
        this.$refs.recaptcha.reset();
      }

      return this.$refs.form.reset();
    },
    captchaVerify: function (response) {
      this.requestData.captcha = response;
    },
    submitRequest(e) {
      e.preventDefault();

      this.localAlert = null;
      this.validationErrors = [];

      if (false === this.tcCheckbox) {
        this.localAlert = {
          type: "error",
          color: "red",
          message:  this.$translator.trans("It's mandatory to agree with our terms and conditions!"),
        };
        return false;
      }

      if (false === this.validate()) {
        //error
        this.localAlert = {
          type: "error",
          color: "red",
          message:  this.$translator.trans("Please check the form below for errors!"),
        };
        return false;
      }

      this.isLoading = true;

      let data = this._.cloneDeep(this.requestData);

      data.servicesList = data.servicesList.map(function (service) {
        return service.guid;
      });
      data.spokenLanguages = data.spokenLanguages.map(function (
        spokenLanguage
      ) {
        return spokenLanguage.code;
      });

      data.destinationCountry = data.destinationCountry
        ? data.destinationCountry.code
        : null;
      data.currentCountry = data.currentCountry
        ? data.currentCountry.code
        : null;

      let $this = this;
      fetch(process.env.VUE_APP_API_URL + "/help-requests", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.$store.getters.getLocale,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message ? data.message :  this.$translator.trans("Submit failed!"),
            };

            if (typeof data.errors !== "undefined") {
              for (let i = 0; i < data.errors.length; i++) {
                let error = data.errors[i];
                $this.validationErrors.push({
                  type: "error",
                  color: "red",
                  message: Object.values(error)[0],
                });
              }
            }

            if (this.$refs.recaptcha) {
              this.$refs.recaptcha.reset();
            }

            return false;
          }

          this.localAlert = {
            type: "success",
            color: "green",
            message:  this.$translator.trans("Help request registered successfully! Check your email!"),
          };

          window.scrollTo(0, 0);
          this.reset();
        })
        .catch((error) => {
          console.error("Error:", error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message ? error.message :  this.$translator.trans("Submit failed!"),
          };
        });

      this.isLoading = false;
      this.validationErrors = [];
    },
  },
};
</script>
